import { showLoading } from 'react-redux-loading-bar';

import { infoMessage } from './message';
import locale from './../utils/localization.json';
import { updateNotifications } from '../actions/viewer';
import { meterToFeet } from './convert';

let _cutplanesChanged = false;
let _analysis = false;
let _min;
let _max;

const onMouseUp = (viewer, dispatch, lang) => {
    if (_cutplanesChanged) {
        updatePointCloud(viewer, dispatch, lang);
        _cutplanesChanged = false;
    }
};

const onCutplanesChangedEvent = async () => {
    if (!_cutplanesChanged) {
        _cutplanesChanged = true;
    }
};

const onToolChanged = async (
    dispatch,
    lang,
    { toolName, active, target: viewer }
) => {
    if (toolName === 'section' && active) {
        viewer?.addEventListener(
            window.Autodesk.Viewing.CUTPLANES_CHANGE_EVENT,
            onCutplanesChangedEvent
        );
        document
            .getElementById('viewer')
            .addEventListener(
                'mouseup',
                onMouseUp.bind(null, viewer, dispatch, lang)
            );

        _cutplanesChanged = true;
        setTimeout(() => {
            onMouseUp(viewer, dispatch, lang);
        }, 1000);
    } else if (toolName === 'section' && !active) {
        viewer?.removeEventListener(
            window.Autodesk.Viewing.CUTPLANES_CHANGE_EVENT,
            onCutplanesChangedEvent
        );

        _cutplanesChanged = true;
        onMouseUp(viewer, dispatch, lang);
    }
};

const updatePointCloud = async (viewer, dispatch, lang) => {
    const extension = viewer?.getExtension('PointCloudExtension');

    if (extension !== undefined) {
        await dispatch(
            updateNotifications('section', locale[lang].sectionNotification)
        );
        setTimeout(async () => {
            for (var key in viewer?.impl?.overlayScenes) {
                if (/^POINTCLOUD_/.test(key)) {
                    if (extension[key]) {
                        viewer?.impl?.removeOverlayScene(key);
                        viewer?.impl?.createOverlayScene(key);
                        extension._addPointCloud(
                            key,
                            extension[key],
                            _analysis,
                            _min,
                            _max,
                            true
                        );
                    }
                }
            }
            await dispatch(updateNotifications('section'));
        }, 500);
    }
};

export const addPointCloud = (
    viewer,
    filename,
    name,
    dispatch,
    analysis,
    min,
    max,
    url,
    lang,
    loadedPointClouds,
    setLoadedPointClouds
) => {
    return new Promise(async (resolve, reject) => {
        try {
            let extension = viewer?.loadedExtensions.PointCloudExtension;
            if (extension === undefined) {
                extension = await viewer?.loadExtension('PointCloudExtension');

                viewer?.addEventListener(
                    window.Autodesk.Viewing.TOOL_CHANGE_EVENT,
                    onToolChanged.bind(null, dispatch, lang)
                );
            }

            _analysis = analysis;
            _min = min;
            _max = max;

            viewer?.impl?.createOverlayScene('POINTCLOUD_' + filename);

            if (extension['POINTCLOUD_' + filename]) {
                await dispatch(showLoading('viewer'));
                setTimeout(() => {
                    extension._addPointCloud(
                        'POINTCLOUD_' + filename,
                        extension['POINTCLOUD_' + filename],
                        _analysis,
                        _min,
                        _max,
                        true
                    );
                    resolve();
                }, 500);
            } else {
                infoMessage(`"${name}" ${locale[lang].pointCloudWaiting}`);

                let progress = 0;
                let preProgress;

                try {
                    const controller = new AbortController();
                    const signal = controller.signal;

                    const updatedLoadedPointClouds = [...loadedPointClouds];

                    const index = updatedLoadedPointClouds.findIndex(
                        (p) => p.filename === filename
                    );

                    updatedLoadedPointClouds[index].controller = controller;

                    setLoadedPointClouds([...updatedLoadedPointClouds]);

                    const response = await fetch(url, { signal });
                    const reader = response.body?.getReader();

                    if (!reader) {
                        return;
                    }

                    const contentLength = +(
                        response.headers?.get('Content-Length') || 0
                    );
                    let receivedLength = 0;
                    let chunks = [];

                    while (true) {
                        if (preProgress !== progress) {
                            await dispatch(
                                updateNotifications(
                                    filename,
                                    `${locale[lang].pointCloudNotification} "${name}" : ${progress} %`
                                )
                            );
                            preProgress = progress;
                        }

                        const { done, value } = await reader.read();

                        if (done) {
                            break;
                        }

                        chunks.push(value);
                        receivedLength += value.length;

                        progress = Math.floor(
                            (receivedLength / contentLength) * 100
                        );

                        if (preProgress !== progress && progress < 100) {
                            dispatch(
                                updateNotifications(
                                    filename,
                                    `${locale[lang].pointCloudNotification} "${name}" : ${progress} %`
                                )
                            );

                            const _progress = progress;

                            setLoadedPointClouds((prev) => {
                                const updatedLoadedPointClouds = [...prev];
                                const index =
                                    updatedLoadedPointClouds.findIndex(
                                        (p) => p.filename === filename
                                    );

                                updatedLoadedPointClouds[index].progress =
                                    _progress;
                                return [...updatedLoadedPointClouds];
                            });

                            preProgress = progress;
                        }
                    }

                    let chunksAll = new Uint8Array(receivedLength);
                    let position = 0;
                    for (let chunk of chunks) {
                        chunksAll.set(chunk, position);
                        position += chunk.length;
                    }

                    const data = new TextDecoder('utf-8').decode(chunksAll);

                    dispatch(updateNotifications(filename));
                    dispatch(showLoading('viewer'));

                    setTimeout(() => {
                        const lines = data.split('\n');
                        lines.shift();
                        lines.pop();

                        const points = lines.map((line) => {
                            const point = line.split(' ');
                            return {
                                X: meterToFeet(point[0]),
                                Y: meterToFeet(point[1]),
                                Z: meterToFeet(point[2]),
                                R: point[3],
                                G: point[4],
                                B: point[5],
                                D: point[6],
                            };
                        });

                        extension._addPointCloud(
                            'POINTCLOUD_' + filename,
                            points,
                            _analysis,
                            _min,
                            _max
                        );

                        resolve();
                    }, 500);
                } catch (err) {
                    dispatch(updateNotifications(filename));
                    reject(err);
                }
            }
        } catch (err) {
            reject(err);
        }
    });
};

export const hidePointCloud = async (viewer, filename) => {
    viewer?.impl?.removeOverlayScene('POINTCLOUD_' + filename);
};
