import { hideLoading, showLoading } from 'react-redux-loading-bar';

import { successMessage, infoMessage } from './../utils/message';
import {
    getModels,
    deleteModel,
    uploadObject,
    uploadChunk,
    downloadModels,
} from './../services/modelService';
import { shortString } from '../utils/string';
import locale from '../utils/localization.json';

export const getAllModels = () => {
    return async (dispatch, getState) => {
        try {
            const { project_id, permissions } = getState().currentProject;
            dispatch(showLoading());
            const { status, data } = await getModels(project_id);
            const models = [];
            if (status === 200) {
                data.objects.forEach((model) => {
                    model.versions.forEach((version) => {
                        models.push({
                            _id: model._id,
                            name: model.name,
                            type: model.type,
                            date: version.date,
                            version: version.number,
                            progress: version.progress,
                            urn: version.urn,
                        });
                    });
                });
            }

            models.sort((a, b) =>
                b.date > a.date ? 1 : a.date > b.date ? -1 : 0
            );

            await dispatch({ type: 'INIT_MODEL', payload: models });
            if (permissions?.model?.download) dispatch(getModelsUrls());
        } catch {
        } finally {
            dispatch(hideLoading());
        }
    };
};

export const getModelsUrls = () => {
    return async (dispatch, getState) => {
        const models = [...getState().models];

        try {
            const { status, data } = await downloadModels();

            if (status === 200) {
                const updatedModels = models.map((model) => {
                    model.url = data?.find(
                        ({ name }) =>
                            name ===
                            `${model._id}_${model.version}.${model.type}`
                    ).url;

                    return model;
                });

                await dispatch({
                    type: 'UPDATE_MODEL',
                    payload: [...updatedModels],
                });
            }
        } catch {}
    };
};

export const handleModelUpload = (body, callback, isLarge) => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        const project_id = getState().currentProject.project_id;

        try {
            const filename = body.get('files').name;

            infoMessage(
                `"${shortString(filename, 23)}" ${
                    locale[lang].uploadStart1
                }${'\n'}${locale[lang].uploadStart2}`
            );

            const config = {
                onUploadProgress: callback,
            };

            let response;

            if (!isLarge)
                response = await uploadObject(body, project_id, config);
            else response = await uploadChunk(body, project_id, config);

            const { status } = response;

            if (status === 201) {
                successMessage(
                    `"${shortString(filename, 23)}" ${locale[lang].uploaded}`
                );
                dispatch(getAllModels());
            }
        } catch {}
    };
};

export const handleModelTranslate = (_id, versions) => {
    return async (dispatch, getState) => {
        let updatedModels = [...getState().models].map((model) => {
            if (model._id === _id) {
                model.progress = versions?.find(
                    (version) => version.number === model.version
                ).progress;

                model.urn = versions?.find(
                    (version) => version.number === model.version
                ).urn;
            }

            return model;
        });

        await dispatch({
            type: 'UPDATE_MODEL',
            payload: [...updatedModels],
        });
    };
};

export const handleModelDelete = () => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        const project_id = getState().currentProject.project_id;
        const currentObject = getState().currentObject;

        try {
            const { status } = await deleteModel(
                {
                    modelId: currentObject._id,
                    number: currentObject.version,
                },
                project_id
            );

            if (status === 200) {
                successMessage(
                    `"${shortString(
                        `${currentObject.name}.${currentObject.type}`,
                        23
                    )}" ${locale[lang].deleted}`
                );
                dispatch(getAllModels());
            }
        } catch {}
    };
};

export const setCurrentModel = (model) => {
    return async (dispatch) => {
        await dispatch({ type: 'SET_MODEL', payload: model });
    };
};
