import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { hideLoading } from 'react-redux-loading-bar';

import { mainContext } from './mainContext';
import { setCurrentModel } from '../../actions/model';
import {
    createButton,
    createToolbarGroup,
    getAccessToken,
    getProjectBasePoint,
    getProjectBasePointPosition,
    IcHidden,
    IcShow,
    onDocumentLoadFailure,
} from '../../utils/forge';
import { errorMessage, warnMessage } from '../../utils/message';
import {
    setGeometries,
    setViewer,
    updateNotifications,
} from '../../actions/viewer';
import locale from './../../utils/localization.json';
import { hidePointCloud, addPointCloud } from '../../utils/pointcloud';
import { emptyIssues } from './../../actions/issue';
import log from '../../utils/log';

const MainContext = ({ children, history, location }) => {
    const dispatch = useDispatch();

    const lang = useSelector((state) => state.language);
    const viewer = useSelector((state) => state.viewer);
    const notifications = useSelector((state) => state.notifications);

    const [currentLinks, setCurrentLinks] = useState([]);
    const [loadedPointClouds, setLoadedPointClouds] = useState([]);

    useEffect(() => {
        removeAllPointClouds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const lunchViewer = async (model) => {
        history.push('/viewer');
        // history.push('/viewer?disableIndexedDb=true');
        // window.DISABLE_INDEXED_DB = true;

        await dispatch(setCurrentModel(model));
        initViewer()
            .then(
                (viewer) =>
                    dispatch(setViewer(viewer)) && loadModel(viewer, model, 0)
            )
            .catch((err) => log.error(err));
    };

    const initViewer = () => {
        return new Promise((resolve, reject) => {
            var options = {
                env: 'AutodeskProduction2',
                api: 'streamingV2_EU',
                // api: 'derivativeV2_EU',
                getAccessToken,
                language: process.env.REACT_APP_LANGUAGE,
                // useConsolidation: true,
                // consolidationMemoryLimit: 150 * 1024 * 1024, // 150MB
            };

            window.Autodesk.Viewing.Initializer(options, () => {
                const config = {
                    extensions: [
                        'Autodesk.DocumentBrowser',
                        'Autodesk.Viewing.MarkupsCore',
                        // 'Autodesk.AEC.LevelsExtension', // TODO: bug > not section point clouds
                        // 'Autodesk.glTF'
                    ],
                };

                const container = document.getElementById('viewer');

                if (container) {
                    // fix viewer-keeps-showing-old-model bug
                    // if still not solved add it before loading the model
                    // window.Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS[
                    //     'If-Modified-Since'
                    // ] = Date();

                    const viewer = new window.Autodesk.Viewing.GuiViewer3D(
                        container,
                        config
                    );

                    viewer.start();

                    viewer.addEventListener(
                        window.Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT,
                        async (x) => {
                            viewer.setLightPreset(1);
                            viewer.setQualityLevel(false, false);
                            viewer.setGhosting(false);
                            viewer.setGroundShadow(false);
                            viewer.setGroundReflection(false);
                            viewer.setEnvMapBackground(false);
                            viewer.setProgressiveRendering(true);
                            viewer.setOptimizeNavigation(true);

                            viewer.projectBasePoint = await getProjectBasePoint(
                                viewer.model
                            );
                            viewer.projectBasePointPosition =
                                await getProjectBasePointPosition(
                                    viewer.model,
                                    viewer.projectBasePoint
                                );
                        }
                    );

                    const viewerTools = [
                        {
                            group: 'navTools',
                            control: 'toolbar-orbitTools',
                        },
                        {
                            group: 'navTools',
                            control: 'toolbar-cameraSubmenuTool',
                        },
                        {
                            group: 'modelTools',
                            control: 'toolbar-sectionTool',
                        },
                    ];

                    viewer.addEventListener(
                        window.Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                        () => {
                            viewerTools.forEach(({ group, control }) =>
                                viewer.toolbar
                                    ?.getControl(group)
                                    ?.getControl(control)
                                    ?.container.addEventListener(
                                        'mousedown',
                                        (event) =>
                                            event.button === 2 &&
                                            viewer.toolbar
                                                .getControl(group)
                                                .getControl(control)
                                                .arrowButton.container.click()
                                    )
                            );

                            viewer
                                .getExtension('Autodesk.BimWalk')
                                ?.tool?.navigator?.enableGravity(false);
                        }
                    );

                    viewer.addEventListener(
                        window.Autodesk.Viewing.TOOLBAR_CREATED_EVENT,
                        (e) => {
                            if (viewer.model?.myData?.is2d)
                                viewer
                                    ?.getExtension(
                                        'Autodesk.AEC.LevelsExtension'
                                    )
                                    ?.levelsButton.setVisible(false);

                            const customGroup =
                                createToolbarGroup('customGroup');
                            viewer.toolbar.addControl(customGroup);
                            let isHidden = false;
                            let hideButton;
                            const hideModel = () => {
                                if (isHidden) {
                                    viewer.isolate();
                                    hideButton.container.children[0].innerHTML =
                                        IcHidden;
                                    hideButton.setToolTip(
                                        locale[lang].hideModel
                                    );
                                } else {
                                    viewer.hideAll();
                                    hideButton.container.children[0].innerHTML =
                                        IcShow;
                                    hideButton.setToolTip(
                                        locale[lang].showModel
                                    );
                                    setCurrentLinks([]);
                                }
                                isHidden = !isHidden;
                            };
                            hideButton = createButton(
                                'hideModel',
                                hideModel,
                                IcHidden,
                                locale[lang].hideModel
                            );
                            customGroup.addControl(hideButton);
                        }
                    );

                    resolve(viewer);
                } else {
                    reject('viewer container not found!');
                }
            });
        });
    };

    const loadModel = (viewer, model, index) => {
        removeAllPointClouds();

        viewer
            ?.getExtension('Autodesk.AEC.LevelsExtension')
            ?.levelsPanel?.setVisible(false);
        viewer
            ?.getExtension('Autodesk.AEC.LevelsExtension')
            ?.levelsPanel?.container.remove();

        const onDocumentLoadSuccess = (doc) => {
            if (doc?.docRoot?.data?.status === 'success') {
                const geometries = doc.getRoot().search({ type: 'geometry' });

                dispatch(setCurrentModel(model));
                dispatch(setGeometries(geometries));
                dispatch(emptyIssues());
                setLoadedPointClouds([]);
                setCurrentLinks([]);

                doc.downloadAecModelData();

                viewer
                    ?.loadDocumentNode(doc, geometries[index], {
                        createWireframe: false,
                        // applyScaling: 'm',
                        isAEC: true,
                        // applyRefPoint: true,
                        skipHiddenFragments: true,
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
            } else errorMessage(locale[lang].onDocumentLoadFailureErrorCode9);
        };

        window.Autodesk.Viewing.Document.load(
            'urn:' + model.urn.replace('_', '/'),
            onDocumentLoadSuccess,
            onDocumentLoadFailure
        );
    };

    const validator = useRef(
        new SimpleReactValidator({
            messages: {
                required: locale[lang].required,
                max: locale[lang].maxSize,
            },
            element: (message) => (
                <div className="mt-1 text-modelic-error-dark text-sm font-poppins">
                    {message}
                </div>
            ),
        })
    );

    const handlePointClouds = async (
        filename,
        name,
        showDistance,
        min,
        max,
        url
    ) => {
        const pointCloudIndex = loadedPointClouds.findIndex(
            (p) => p.filename === filename
        );
        if (pointCloudIndex >= 0) {
            if (loadedPointClouds[pointCloudIndex].isLoaded) {
                const filteredPointClouds = loadedPointClouds.filter(
                    (p) => p.filename !== filename
                );
                setLoadedPointClouds([
                    ...filteredPointClouds,
                    {
                        filename,
                        isLoaded: false,
                        showDistance,
                        percentages:
                            loadedPointClouds[pointCloudIndex]?.percentages,
                    },
                ]);
                hidePointCloud(viewer, filename);
            } else if (loadedPointClouds[pointCloudIndex].isLoaded === false) {
                const filteredPointClouds = loadedPointClouds.filter(
                    (p) => p.filename !== filename
                );

                setLoadedPointClouds([
                    ...filteredPointClouds,
                    {
                        filename,
                        isLoaded: true,
                        showDistance,
                        percentages:
                            loadedPointClouds[pointCloudIndex]?.percentages,
                    },
                ]);
                try {
                    await addPointCloud(
                        viewer,
                        filename,
                        name,
                        dispatch,
                        showDistance,
                        min,
                        max
                    );
                } catch (err) {
                    log.error(err);
                    setLoadedPointClouds([
                        ...filteredPointClouds,
                        {
                            filename,
                            isLoaded: false,
                        },
                    ]);
                } finally {
                    dispatch(hideLoading('viewer'));
                }
            } else {
                loadedPointClouds[pointCloudIndex].controller.abort();
            }
        } else {
            if (loadedPointClouds.length >= 3)
                warnMessage(locale[lang].limitPointCloud);

            const updatedLoadedPointClouds = [
                ...loadedPointClouds,
                { filename /*, isLoaded: false*/ },
            ];
            setLoadedPointClouds(updatedLoadedPointClouds);

            try {
                await addPointCloud(
                    viewer,
                    filename,
                    name,
                    dispatch,
                    showDistance,
                    min,
                    max,
                    url,
                    lang,
                    updatedLoadedPointClouds,
                    setLoadedPointClouds
                );
                setLoadedPointClouds((loadedPointClouds) => {
                    const filteredPointClouds = loadedPointClouds.filter(
                        (p) => p.filename !== filename
                    );

                    const pointcloud = loadedPointClouds.find(
                        (p) => p.filename === filename
                    );

                    return [
                        ...filteredPointClouds,
                        {
                            filename,
                            isLoaded: true,
                            showDistance,
                            percentages: pointcloud.percentages,
                        },
                    ];
                });
            } catch (err) {
                if (err.name !== 'AbortError' && err.name !== 'CanceledError')
                    log.error(err);

                setLoadedPointClouds((loadedPointClouds) => {
                    const filteredPointClouds = loadedPointClouds.filter(
                        (p) => p.filename !== filename
                    );

                    return [...filteredPointClouds];
                });
            } finally {
                dispatch(hideLoading('viewer'));
            }
        }
    };

    const removeAllPointClouds = () => {
        [...loadedPointClouds].forEach(({ filename, controller }) => {
            hidePointCloud(viewer, filename);
            controller?.abort();
        });

        const percentages = notifications.filter(({ id }) =>
            id.includes('percentages')
        );
        percentages.forEach(({ id }) => dispatch(updateNotifications(id)));
    };

    const deletePointCloud = (filename) => {
        const pointCloudIndex = loadedPointClouds.findIndex(
            (p) => p.filename === filename
        );

        // hide from viewer
        if (pointCloudIndex >= 0) {
            const filteredPointClouds = loadedPointClouds.filter(
                (p) => p.filename !== filename
            );

            setLoadedPointClouds([...filteredPointClouds]);
            hidePointCloud(viewer, filename);
        }

        // delete from viewer
        const extension = viewer?.getExtension('PointCloudExtension');
        if (extension !== undefined && extension['POINTCLOUD_' + filename])
            delete extension['POINTCLOUD_' + filename];
    };

    return (
        <mainContext.Provider
            value={{
                handlePointClouds,
                lunchViewer,
                loadModel,
                validator,
                currentLinks,
                setCurrentLinks,
                loadedPointClouds,
                setLoadedPointClouds,
                deletePointCloud,
                removeAllPointClouds,
            }}
        >
            {children}
        </mainContext.Provider>
    );
};

export default withRouter(MainContext);
